import Swiper from "swiper";
console.log("slider");

const swiper = new Swiper(".carousel-swiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
});

document
    .querySelector(".carousel-container .arrow-next")
    .addEventListener("click", (e) => {
        e.preventDefault();
        swiper.slideNext();
    });

document
    .querySelector(".carousel-container .arrow-prev")
    .addEventListener("click", (e) => {
        e.preventDefault();
        swiper.slidePrev();
    });
