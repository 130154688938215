import Swiper from "swiper";
console.log("slider private");

const swiper = new Swiper(".private-swiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
        768: {
            slidesPerView: 2,
        },

        940: {
            slidesPerView: 3,
        },

        1024: {
            slidesPerView: 4,
        },
    },
});

document
    .querySelector(".arrow-nav--private .arrow-next")
    .addEventListener("click", (e) => {
        e.preventDefault();
        swiper.slideNext();
    });

document
    .querySelector(".arrow-nav--private .arrow-prev")
    .addEventListener("click", (e) => {
        e.preventDefault();
        swiper.slidePrev();
    });
